<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner m-0">
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-img
          src="@/@core/assets/image/logo-komerce-new-tag.png"
        />
      </b-col>

      <b-card :class="modeLogin === true ? 'text-white mt-2' : 'd-none'">
        <b-card-title class="mb-1 text-center">
          Masuk
        </b-card-title>
        <b-card-text class="mb-2 text-center text-black">
          Silahkan masuk dan memulai kemudahan mengelola e-commerce dalam 1 tempat.
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <b-form-group
              label="Username atau Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                label="Username atau Email"
                vid="email"
                rules="required"
                :custom-messages="custommessages1"
              >
                <b-form-input
                  id="login-email"
                  v-model="usernameEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@mail.com"
                  @keypress="NoSpace($event)"
                />
                <small class="text-primary">{{ errors[0] }}</small>
                <b-col>
                  <b-row
                    v-if="!!error"
                    class="align-items-center justify-content-between mt-50"
                  >
                    <small
                      class="text-primary"
                    >
                      <strong>
                        {{ error }}
                      </strong>
                    </small>
                    <b-button
                      v-if="showResendEmailVerification"
                      class="ml-50 btn-icon"
                      variant="flat-primary"
                      size="sm"
                      @click="resendEmailVerification"
                    >
                      <b-spinner
                        v-if="loadingResendVerification"
                        small
                      />
                      <strong>
                        Kirim Ulang
                      </strong>
                    </b-button>
                  </b-row>
                  <b-row
                    v-if="messageResendEmailVerification !== ''"
                    class="align-items-center mt-50"
                  >
                    <small
                      class="text-primary"
                    >
                      <strong>
                        {{ messageResendEmailVerification }}
                      </strong>
                    </small>
                  </b-row>
                </b-col>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <label for="login-password">Password</label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
                :custom-messages="custommessages"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                    @keypress="NoSpace($event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="flex justify-items-end w-full">
                <button
                  type="button"
                  class="text-primary text-sm mt-[8px] focus:outline-none"
                  @click="$router.push({ name: 'auth-forgot-password' })"
                >
                  Lupa Password?
                </button>
              </div>

              <span
                v-if="isShowErrorForgotPassword"
                class="my-[8px] text-sm text-[#E31A1A]"
              >
                Tidak bisa reset password, silahkan hubungi team Komerce
              </span>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group v-if="false">
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              Masuk
            </b-button>
          </b-form>
        </validation-observer>

        <!-- </b-col> -->
      </b-card>

      <b-card :class="modeVerificationEmail === true ? 'mt-2 p-1' : 'd-none'">
        <b-card-title class="mb-1 text-center">
          <h3 class="text-black">
            <strong>
              Verifikasi Email
            </strong>
          </h3>
        </b-card-title>
        <b-card-text class="mb-1 text-center text-black">
          Cek email kamu, verifikasi telah dikirimkan.
          Belum menerima? {{ countTimerEmail === 0 ? 'Kirim ulang (60 detik)' : '' }}
        </b-card-text>
        <b-row class="justify-content-center mb-1">
          <small>Mohon tunggu {{ countTimerEmail }} detik untuk mengirim ulang.</small>
        </b-row>

        <b-row class="justify-content-center mb-1">
          <b-button
            :variant="countTimerEmail === 0 ? 'flat-primary' : 'flat-dark'"
            size="sm"
            :disabled="countTimerEmail !== 0"
            class="btn-icon"
            @click="resendEmailVerification"
          >
            Kirim Ulang
          </b-button>
        </b-row>

        <b-button
          variant="primary"
          block
          @click="handleChangeModePage"
        >
          Kembali Masuk
        </b-button>
      </b-card>
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BAlert,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { NoSpace } from '@/libs/helpers'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BForm,
    // BAlert,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    // BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userId: '',
      error: '',
      status: '',
      showResendEmailVerification: true,
      usernameEmail: '',
      password: '',
      loading: false,
      // validation rules
      required,
      email,
      custommessages: {
        required: 'Mohon masukan password Kamu.',
      },
      custommessages1: {
        required: 'Mohon masukan username atau email.',
      },

      messageResendEmailVerification: '',
      loadingResendVerification: false,

      countTimerEmail: 60,

      // Mode Page
      modeVerificationEmail: false,
      modeLogin: true,

      fcmToken: '',
      NoSpace,

      isShowErrorForgotPassword: false,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.showResendEmailVerification = false
          this.error = ''
          this.userId = ''

          useJwt
            .login({
              username_email: this.usernameEmail,
              password: this.password,
              login_from: 'website',
            })
            .then(response => {
              if (response.data.status === false && response.data.data) {
                const { data } = response.data
                this.$swal({
                  html: `<h3 class="font-bold text-2xl mt-[8px] text-[#333333]">Gagal Login</h3> <p class="my-[18px]">Tersisa ${data.login_attempt}x kesempatan untuk login. Apabila setelah 3x percobaan login tetap gagal, kamu dapat mencobanya lagi setelah 24 jam kedepan</p>`,
                  imageUrl: 'https://storage.googleapis.com/komerce/core/icon-popup-warning.png',
                  imageWidth: 104,
                  imageHeight: 100,
                  width: 600,
                  padding: '44px 24px',
                  confirmButtonText: 'Mengerti',
                  confirmButtonClass: 'btn btn-primary w-full',
                  customClass: {
                    actions: 'mt-0 px-0',
                  },
                })
              } else if (response.data.status === false) {
                this.error = 'Maaf, username atau password yang Kamu masukan salah'
              } else if (response.data.data.email_verified_at === null) {
                this.error = 'Email belum terverifikasi, silahkan verifikasi email-mu terlebih dahulu'
              } else {
                useJwt.setToken(response.data.data)
                this.getUser(response.data.data)
              }
            })
            .catch(error => {
              if (error && error.response && error.response.data.code === 400) {
                this.$swal({
                  html: '<h3 class="font-semibold text-2xl mt-[8px] text-[#333333]">Gagal Login</h3> <p class="my-[18px]">Login Gagal 3x. Silakan coba lagi setelah 24 jam ke depan</p>',
                  imageUrl: 'https://storage.googleapis.com/komerce/core/icon-popup-warning.png',
                  confirmButtonText: 'Mengerti',
                  imageWidth: 104,
                  imageHeight: 100,
                  width: 600,
                  padding: '44px 24px',
                  confirmButtonClass: 'btn btn-primary w-full',
                  customClass: {
                    actions: 'mt-0 px-0',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: 'Gagal',
                    text: 'Gagal untuk login, silahkan coba lagi!',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getUser(userData) {
      this.userId = userData.id
      // eslint-disable-next-line no-param-reassign
      // if (userData.email_verified_at !== null) userData.email_verified_at = null

      this.$http
        .post('/user/get-profile', {
          user_id: this.userId,
        })
        .then(async response => {
          let ability = []

          let { data } = response.data
          data = Array.isArray(data) ? data[0] : data
          const roleType = data?.mitras?.mitra_type.toUpperCase()

          if (!['EKSPEDISI', 'WAREHOUSE'].includes(roleType)) {
            this.error = 'Akun anda tidak memiliki hak akses untuk masuk.'
            return
          }

          switch (roleType) {
            case 'EKSPEDISI':
              ability = [
                { action: 'manage', subject: 'Dashboard' },
                { action: 'manage', subject: 'Mitra Ticketing' },
                { action: 'manage', subject: 'MonitoringPickup' },
              ]
              break
            case 'WAREHOUSE':
              ability = [
                { action: 'manage', subject: 'Pin' },
                { action: 'manage', subject: 'RekeningBank' },
                { action: 'manage', subject: 'Profile' },
                { action: 'manage', subject: 'carigudang' },
                { action: 'manage', subject: 'gudangku' },
                { action: 'manage', subject: 'InfoGudangku' },
                { action: 'manage', subject: 'StokGudang' },
                { action: 'manage', subject: 'DataInbound' },
                { action: 'manage', subject: 'Keuangan' },
                { action: 'manage', subject: 'Outbound' },
                { action: 'manage', subject: 'DashboardWarehouse' },
              ]
              break
            default:
              break
          }

          data.ability = ability
          this.$ability.update(ability)

          secureLocalStorage.set('userData', data)
          this.$store.commit('auth/UPDATE_USER_DATA', data)

          this.$router
            .go(getHomeRouteForLoggedInUser(roleType))
            .then(() => {})
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: 'Failed',
                  text: error.response.data.error,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            })
        })
        .finally(() => {
          this.loading = false
        })
    },
    resendEmailVerification() {
      this.loadingResendVerification = true
      this.showResendEmailVerification = true
      if (this.countTimerEmail !== 60) {
        this.countTimerEmail = 60
      }

      this.$http
        .get(`/resend_verification_email/${this.userId}`)
        .then(() => {
          this.error = ''
          this.loadingResendVerification = false
          this.modeLogin = false
          this.modeVerificationEmail = true
          this.countDownTimer()
        })
        .catch(() => {
          this.loadingResendVerification = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              text: 'Gagal untuk login, silahkan coba lagi!',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getPartnerProfile(userId) {
      return this.$http
        .get(`/user/partner/get-profile/${userId}`)
        .then(response => response.data.data[0])
        .finally(() => {
          this.loading = false
        })
    },
    getAccessKomship(id) {
      const params = {
        user_id: id,
      }
      return this.$http.get('/user/partner/get-menu-komship-member', {
        params,
      }).then(response => response.data.data)
    },
    logout() {
      useJwt
        .logout({})
        .then(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          secureLocalStorage.clear(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChangeModePage() {
      this.modeVerificationEmail = false
      this.modeLogin = true
    },
    countDownTimer() {
      if (this.countTimerEmail > 0) {
        setTimeout(() => {
          this.countTimerEmail -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    handleClickForgotPassword() {
      this.isShowErrorForgotPassword = true

      setTimeout(() => {
        this.isShowErrorForgotPassword = false
      }, 4000)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

a {
    color: #f95031 !important;
    background-color: transparent !important;
}
</style>
